@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";

.container {
  @media (min-width: 1430px) {
    width: 1400px;
  }
}

// Roboto Font
@font-face {
    font-family: 'Roboto Thin';
    src: url('/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto Light';
    src: url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

// Noto Sans Font
/*
@font-face {
    font-family: 'NotoSans Thin';
    src: url(/fonts/NotoSans/NotoSansCJKjp-Thin.otf);
    font-weight: 100;
}

@font-face {
    font-family: 'NotoSans Light';
    src: url(/fonts/NotoSans/NotoSansCJKjp-Light.otf);
    font-weight: 300;
}

@font-face {
    font-family: 'NotoSans';
    src: url(/fonts/NotoSans/NotoSansCJKjp-Regular.otf);
    font-weight: 400;
}
*/
@btn-font-weight: 300;

/*
 * Style
 */
body, html {
    color: #4A4A4A;
    font-family: "Roboto Light", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Arial", "Verdana", "sans-serif";
    height: 100%;
}

html {
    overflow: hidden\9; /* IE10以下 */
}

body {
    padding-top: 50px;
    overflow: auto\9; /* IE10以下 */
}

@media all and (-ms-high-contrast: none){
    html {
        overflow: hidden; /* IE10以上 */
    }
    
    body{
        overflow: auto; /* IE10以上 */
    }
}

ul {
    padding-inline-start: inherit;
    list-style-position: inside;
    list-style-type: disc;
}

.btn:focus, 
.btn:active:focus, 
.btn.active:focus, 
.btn.focus, 
.btn:active.focus, 
.btn.active.focus {
  outline: none;
}

.btn-default {
    background-color: @gray-lighter;
}

.btn-default-dark {
    background-color: @gray;
    color: #fff;

    &:active,
    &.active {
        &:focus,
        &.focus {
          color: #fff;
        }
    }

    &:hover,
    &:focus,
    &.focus {
        color: #fff;
    }

    &:active,
    &.active {
        color: #fff;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        color: #fff;
    }
}

.list-group-item.active {
    .badge {
        .text-primary {
            color: #fff;
        }
    }
}

.list-group.list-group-flush {
    border-radius: 0;
    box-shadow: none;

    > .list-group-item {
        border-left-width: 0;
        border-right-width: 0;

        &:first-child {
            border-top-width: 0;
        }

        &:last-child {
            border-bottom-width: 0;
        }
    }
}

.body-gray {
    background-color: #eee;
}

.fixed-header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fadeIn.ng-enter {
  transition:0.3s linear all;
  opacity:0;
}

.fadeIn.ng-enter.ng-enter-active {
  opacity:1;
}

.fadeOut.ng-leave {
  transition:0.5s linear all;
  opacity:1;
}
.fadeOut.ng-leave.ng-leave-active {
  opacity:0;
}

@media (max-width: @screen-xs-min) {
    table {
        font-size: 11px;
    }
    
    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        padding: 3px;
    }
}

.blur {
    -webkit-filter: blur(6px);
    -ms-filter: blur(6px);
    filter: blur(6px);
}

*:focus,
a:focus,
.active:focus {
    outline: none;
}


/*
 * datepicker
 */
.datepicker .dropdown-menu {
    min-width: 200px;
    margin-left: -95px;
    margin-right: 95px;
}

.datepicker table {

    margin: 15px;
    outline: none;
}
.datepicker table .btn-default {
    background: #fff;
    border: 0;
    box-shadow: none;
}
.datepicker table .btn-default:hover {
    background: #f6f6f6;
}
.datepicker table .btn-default.active {
    background: #007aff;
    color: #fff;
    text-shadow: none;
}
.datepicker table .btn-default.active > .text-info {
    color: #fff;
}

/*
 * slider
 */
.rzslider .rz-bar {
    height: 2px;
}

.rzslider .rz-ticks .rz-tick {
    width: 8px;
    height: 8px;
}

/*
 * animate
 */
.animate-show.ng-enter,
.animate-show.ng-leave,
.animate-show.ng-move {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.animate-show.ng-enter {
    opacity: 0;
}
.animate-show.ng-enter.ng-enter-active {
    opacity: 1;
}
.animate-show.ng-leave {
    opacity: 1;
}
.animate-show.ng-leave.ng-leave-active {
    opacity: 0;
}
.animate-show.ng-move {
    opacity: .5;
}
.animate-show.ng-move.ng-move-active {
    opacity: 1;
}

.animate-show.ng-hide-add, 
.animate-show.ng-hide-remove {
  -webkit-transition:all linear 0.2s;
  -moz-transition:all linear 0.2s;
  -o-transition:all linear 0.2s;
  transition:all linear 0.2s;
  display:block!important;
}

.animate-show.ng-hide-add.ng-hide-add-active,
.animate-show.ng-hide-remove {
  opacity:0;
}

.animate-show.ng-hide-add,
.animate-show.ng-hide-remove.ng-hide-remove-active {
  opacity:1;
}

/*
 * animate(slide)
 */
.animate-slide-show.ng-enter,
.animate-slide-show.ng-leave,
.animate-slide-show.ng-move,
.animate-slide-show.ng-hide-add, 
.animate-slide-show.ng-hide-remove{
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
}
.animate-slide-show.ng-enter,
.animate-slide-show.ng-hide-add.ng-hide-add-active,
.animate-slide-show.ng-hide-remove {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
}
.animate-slide-show.ng-enter.ng-enter-active {
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
    opacity: 1;
}
.animate-slide-show.ng-leave.ng-leave-active {
    opacity: 0;
}
.animate-slide-show.ng-move {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 1;
}
.animate-slide-show.ng-move.ng-move-active,
.animate-slide-show.ng-hide-add,
.animate-slide-show.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

/*
 * panel
 */

.panel > .panel-heading:not(:first-child) {
    border-radius: 0;
    border-top: 1px solid @panel-inner-border;
}

.panel > .panel-heading > .btn {
    float: right;
    margin-top: -11px;
    margin-bottom: -11px;
    
    border-radius: 0;
    height: 41px;
}

.panel > .panel-heading > .btn-group {
    float: right;
}

.panel > .panel-heading > .btn-group:first-child {
    margin-right: -16px;
}

.panel > .panel-heading > .btn-group > .btn {
    margin-top: -11px;
    margin-bottom: -11px;

    border-radius: 0;
    height: 41px;
}

.panel > .panel-heading > a.btn,
.panel > .panel-heading > .btn-group > a.btn {
    padding-top: 10px;
}


.panel > .panel-heading > .btn:first-child {
    margin-right: -16px;
}

.panel > .panel-heading:first-child > .btn:first-child {
    margin-right: -16px;
    border-top-right-radius: @border-radius-base;
}

.panel > .panel-heading:first-child > .btn-group:first-child > .btn.dropdown-toggle {
    border-top-right-radius: @border-radius-base;
}

/*
 * Header paddings
 */

.with-full-header {
    padding-top: 115px;
}

.with-page-header {
    padding-top: 70px;
}

/*
 * Font color
 */
.font-color-black {
    color: black;
}

/*
 * infomation group
 */
.info-group {
    position: relative;
    display: table;
    border-collapse: separate;
    margin-bottom: @line-height-computed;
}

.info-group > .info-group-addon {
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    font-weight: normal;
    line-height: 1;
    color: @input-color;
    text-align: center;
    background-color: @input-group-addon-bg;
    //border: 1px solid @input-group-addon-border-color;
    border: none;
    border-right: 0;
    
    // Sizing
    &.input-sm {
        padding: @padding-small-vertical @padding-small-horizontal;
        font-size: @font-size-small;
        border-radius: @border-radius-small;
    }
    &.input-lg {
        padding: @padding-large-vertical @padding-large-horizontal;
        font-size: @font-size-large;
        border-radius: @border-radius-large;
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin-top: 0;
    }

    input[type="text"] {
        border: none;
    }

}

.input-xs {
  .input-size(22px; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @input-border-radius-small);
}

.input-xs + .form-control-feedback {
  width: @input-height-small;
  height: 22px;
  line-height: @input-height-small;
}

.input-group-xs {
    line-height: 1.5;
    
    > .form-control,
    > .input-group-addon,
    > .input-group-btn > .btn{
        height: 22px;
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: @input-border-radius-small;
    }
}

.info-group > .info-group-body {
    display: table-cell;
    width: 100%;
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
    background-color: lighten(@gray, 40%);
    background-image: none;
    border: none;
    
    .box-shadow(inset 0 0 0 rgba(0,0,0,.075));
    .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
}

.info-group.info-group-sm {
    min-height: 27px;
}

.info-group.info-group-sm > .info-group-addon,
.info-group.info-group-sm > .info-group-body {
    padding: @padding-small-vertical @padding-small-horizontal;
    font-size: @font-size-small;
    min-height: 27px;
}

.info-group.info-group-inline {
    margin-right: 5px;
    margin-bottom: 5px;
    float: left;
}

.info-group.info-group-inline > .info-group-addon,
.info-group.info-group-inline > .info-group-body
{
    width: auto;
}

.info-group > *:first-child {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
}

.info-group > *:last-child {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
}

.infoGroupBrandStyle(@color) {
    background-color: @color;
    color: #fff;
    border-color: @color;
    font-weight: bold;
}

.info-group.info-group-default > .info-group-addon:first-child {
    .infoGroupBrandStyle(lighten(@gray, 35%));
}

.info-group.info-group-primary > .info-group-addon,
.info-group > .info-group-addon.info-group-addon-primary {
    .infoGroupBrandStyle(@brand-primary);
}
.info-group.info-group-success > .info-group-addon,
.info-group > .info-group-addon.info-group-addon-success {
    .infoGroupBrandStyle(@brand-success);
}
.info-group.info-group-info > .info-group-addon,
.info-group > .info-group-addon.info-group-addon-info {
    .infoGroupBrandStyle(@brand-info);
}
.info-group.info-group-warning > .info-group-addon,
.info-group > .info-group-addon.info-group-addon-warning {
    .infoGroupBrandStyle(@brand-warning);
}
.info-group.info-group-danger > .info-group-addon,
.info-group > .info-group-addon.info-group-addon-danger {
    .infoGroupBrandStyle(@brand-danger);
}

.info-group.info-group-primary > .info-group-body,
.info-group > .info-group-body.info-group-body-primary {
    color: @brand-primary;
    background-color: lighten(@brand-primary, 40%);
}

.info-group.info-group-success > .info-group-body,
.info-group > .info-group-body.info-group-body-success  {
    color: @brand-success;
    background-color: lighten(@brand-success, 35%);
}

.info-group.info-group-info > .info-group-body,
.info-group > .info-group-body.info-group-body-info  {
    color: @brand-info;
    background-color: lighten(@brand-info, 32%);
}

.info-group.info-group-warning > .info-group-body,
.info-group > .info-group-body.info-group-body-warning  {
    color: @brand-warning;
    background-color: lighten(@brand-warning, 35%);
}

.info-group.info-group-danger > .info-group-body,
.info-group > .info-group-body.info-group-body-danger  {
    color: @brand-danger;
    background-color: lighten(@brand-danger, 35%);
}

.info-group > .info-group-addon.input-btn {
    padding: 0;
}

.info-group.info-group-default > .info-group-addon:first-child {
    color: @input-color;
}

.info-group > .info-group-addon > .btn,
.info-group > .info-group-addon.input-btn > .btn{
    border-radius: 0;
    max-height: 34px;
    border: none;
    background-image: none;
}

.info-group > .info-group-addon:first-child > .btn {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
}

.info-group > .info-group-addon:last-child > .btn {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
}

.info-group .popover {
    color: #4A4A4A;
    width: 100%;
    max-width: 500px;
}

/*
 * IEの×マーク(クリアボタン)を非表示
 */
input::-ms-clear {
    visibility:hidden
}

/*
 * sidebar menu
 */
.nav-sidebar {
    position: fixed;
    top: 165px;
}

.nav-sidebar .nav > li > a:focus,
.nav-sidebar .nav > li > a:hover,
.nav-sidebar .nav > li > a {
    display: block;
    padding: 4px 20px;
    font-size: 13px;
    color: #767676;
    background-color: transparent;
}

.nav-sidebar .nav .nav {
    display: none;
    padding-bottom: 10px
}

@media screen and (min-height: 760px) {
    .nav-sidebar .nav > .active > ul {
        display: block;
    }
}

.nav-sidebar .nav .nav > li > a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 12px;
}

.nav-sidebar .nav > .active:focus > a,
.nav-sidebar .nav > .active:hover > a,
.nav-sidebar .nav > .active > a {
    padding-left: 18px;
    font-weight: bold;
    color: @brand-primary;
    background-color: transparent;
    border-left: 2px solid @brand-primary;
}

.nav-sidebar .nav .nav > .active:focus > a,
.nav-sidebar .nav .nav > .active:hover > a,
.nav-sidebar .nav .nav > .active > a {
    padding-left: 28px;
    font-weight: 500;
}

/*
 * Exchange spf
 */
.info-group.info-group-spf {
    margin-right: 5px;
    float: left;
    width: auto;
    min-height: 36px;
}

.info-group.info-group-spf > .info-group-addon {
    width: auto;
}

.info-group.info-group-spf > .info-group-body {
    width: auto;
    
}

.info-group.info-group-spf > .info-group-body.input-group {
    padding: 0;
    border: 1px solid lighten(@gray, 40%);
}

.info-group.info-group-spf > .info-group-body > input {
    padding-left: 5px;
    border: none;
    min-height: 32px;
}

.info-group.info-group-spf  > *:last-child > input {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
}

.info-group.info-group-spf > .info-group-addon.input-btn {
    padding: 0;
}

.info-group.info-group-spf > .info-group-addon:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-height: 34px;
}

.info-group.info-group-spf.has-error > .info-group-body.input-group,
.info-group.info-group-spf.has-error > .info-group-addon,
.info-group.info-group-spf.has-error  > .info-group-addon.input-btn {
    border-color: @state-danger-text;
}



.info-group.info-group-spf.has-error > .info-group-body > input {
    border-color: lighten(@brand-danger, 30%);
}


.info-group.info-group-spf.has-error > .info-group-addon {
    background-color: lighten(@brand-danger, 30%);
}

.info-group.info-group-spf.has-error > .info-group-addon:first-child {
    color: #fff;
    background-color: @brand-danger;
}

.spf .has-error.popover {
    color: @state-danger-text;
    background-color: @state-danger-bg;
    border-color: @state-danger-text;
}

.spf .info-group .popover {
    width: 370px;
    max-width: 370px;
}

/*
 * modal
 */
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: #000;
}

.center-modal {
    position: fixed;
    top: 30%;
    left: 18.5%;
    z-index: 1050;
    width: 80%;
    height: 80%;
    margin-left: -10%;
}

.modal-header {
    border-top-left-radius: @border-radius-large;
    border-top-right-radius: @border-radius-large;
}

.modal-primary .modal-header {
    //color: #ffffff;
    background-color: lighten(@brand-primary, 45%);
}

.modal-danger .modal-header {
    //color: #ffffff;
    background-color: lighten(@brand-danger, 35%);
}

.modal-success .modal-header {
    background-color: lighten(@brand-success, 35%);
}

.modal-content {
    #gradient > .vertical(@start-color: #ffffff; @end-color: #eeeeee);
}

/*
 * domain group affix
 */

.domain-group-list.affix {
    position: relative;
}

@media (min-width: @screen-sm-min) {
    .domain-group-list.affix {
        position: fixed;
        width: 218px;
    }
}

@media (min-width: @screen-md-min) {
    .domain-group-list.affix {
        position: fixed;
        width: 298px;
    }
}

@media (min-width: @screen-lg-min) {
    .domain-group-list.affix {
        position: fixed;
        width: 358px;
    }
}

/*
 * dropdown-menu
 */
.dropdown-menu > li > span{
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #5e5e5e;
    white-space: nowrap;
}

/*
 * popover
 */
.popover{
    font-size: 12px;
}

/*
 * alert
 */
.alert-danger{
    background-image: none !important;
    filter: none;
}

/*
 * Loader
 */

.loader-container {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    height: 0;
    text-align: center;
    z-index: 10001;
    background: #ddd;
}

.loader-container > .loader {
    margin: 6em auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(42, 200, 69, 0.2);
    border-right: 1.1em solid rgba(42, 200, 69, 0.2);
    border-bottom: 1.1em solid rgba(42, 200, 69, 0.2);
    border-left: 1.1em solid @brand-success;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loadAnime 1.1s infinite linear;
    animation: loadAnime 1.1s infinite linear;
}
.loader-container > .loader,
.loader-container > .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader-container > .loader-message {
    margin-top: -30px;
    font-size: 16px;
    font-weight: 400;
    color: @brand-success;
}

.loader-container.load-danger > .loader {
    border-top: 1.1em solid rgba(255, 45, 85, 0.2);
    border-right: 1.1em solid rgba(255, 45, 85, 0.2);
    border-bottom: 1.1em solid rgba(255, 45, 85, 0.2);
    border-left: 1.1em solid @brand-danger;
}

.loader-container.load-danger > .loader-message {
    color: @brand-danger;
}

@-webkit-keyframes loadAnime {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loadAnime {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*
 * Dimmer
 */
.dimmer {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, .85);
    z-index: 1100;
    color: #fff;
};

.dimmer.dimmer-inverted {
    color: @gray;
    background-color: rgba(255, 255, 255, .85);
}

/*
 * dialog
 */
.dialog{
    left: 0;
    right: 0;
    position: fixed;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1010;
    bottom: 39px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    
    .container{
        padding-left: 275px;
    }
    
    [class^="label-"]{
        color: #fff;
        font-weight: bold;
    }
    
    .btn-default{
        background-color: @gray-lighter;
    }
}

/*
 * Headline
 */
.sub-headline {
    margin-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid @gray-lighter;
    font-weight: bold;
}

/*
 * Sidebar
 */
@sidebar-width: 260px;
.fixed-sidebar {
    position: fixed;
    background: #fff;
    top: 100px;
    bottom: 0;
    overflow: auto;

    .sidebar-content {
        position: relative;
        width: @sidebar-width;
        margin-left: -1000px;
        padding-left: 1000px;
        padding-right: 260px;
        min-height: 40px;
        
        > * {
            width: @sidebar-width;
            > * {
                width: @sidebar-width;
            }
        }

        .list-group {
            box-shadow: none;
            .list-group-item {
                border: none;
                border-radius: 0;
                box-shadow: none;

                .badge {
                    color: @gray;
                    background-color: transparent;
                    border-radius: 0;
                }
            }
            .list-group-item.active {
                background-image: none;

                .badge {
                    color: #fff;
                }
            }

            .list-group-item > .badge {
                float: right;
            }

            .list-group-item > .badge.handle {
                float: left;
                min-height: 25px;
            }

            .list-group-item[draggable='true'] {
                cursor: move;
            }

            .dndPlaceholder {
                background-color: @gray-lighter;
                display: block;
                min-height: 42px;
            }

            .dndDraggingSource {
                display: none;
            }
        }


        &.widh-domain-group-change-order-footer {
            padding-bottom: 45px;
        }

        &.widh-domain-background-processing-1 {
            padding-bottom: 100px;
        }

        &.widh-domain-group-change-order-footer.widh-domain-background-processing-1 {
            padding-bottom: 145px;
        }

        &.widh-domain-background-processing-2 {
            padding-bottom: 200px;
        }

        &.widh-domain-group-change-order-footer.widh-domain-background-processing-2 {
            padding-bottom: 245px;
        }

        &.widh-domain-background-processing-3 {
            padding-bottom: 300px;
        }

        &.widh-domain-group-change-order-footer.widh-domain-background-processing-3 {
            padding-bottom: 345px;
        }

        &.widh-domain-background-processing-4 {
            padding-bottom: 400px;
        }

        &.widh-domain-group-change-order-footer.widh-domain-background-processing-4 {
            padding-bottom: 445px;
        }

        &.widh-domain-background-processing-5 {
            padding-bottom: 500px;
        }

        &.widh-domain-group-change-order-footer.widh-domain-background-processing-5 {
            padding-bottom: 545px;
        }

        &.widh-domain-background-processing-6 {
            padding-bottom: 600px;
        }

        &.widh-domain-group-change-order-footer.widh-domain-background-processing-6 {
            padding-bottom: 645px;
        }

    }

    .sidebar-footer {
        position: fixed;
        bottom: 0;
        background-color: #fff;
        z-index: 1010;

        .progress {
            -webkit-border-radius: 4px;
            -moz-border-radius:4px;
            border-radius: 4px;
        }

        .sidebar-button-group,
        .sidebar-progress {
            border-top: 1px solid #CCCCCC;
        }

    }

}

.fixed-sidebar.sidebar-left {
    width: @sidebar-width;
    margin-left: -1000px;
    padding-left: 1000px;
    padding-right: 260px;
    border-right: 1px solid #CCCCCC;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1020;

    .container-fluid {
        width: @sidebar-width;
    }


    .btn-group {
        padding: 10px 5px;

        > .btn {
            font-weight: bold;
            background-image: none;
            border: none;
            font-size: 11px;
            line-height: 25px;
            padding: 0;
        }

        > .btn-default {
            background-color: @gray-lighter;
        }

    }

    .sidebar-header {
        position: fixed;
        top: 0;
        width: @sidebar-width;
        background-color: #fff;
        z-index: 1010;

        > * {
            width: @sidebar-width;
        }

        > .navbar {
            width: @sidebar-width;
            margin-left: -1000px;
            padding-left: 1000px;
            padding-right: 260px;
            min-height: 40px;
            margin-bottom: 0;



            .navbar-brand,
            .navbar-nav > li > a {
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
            }

            .navbar-brand {
                font-size: 14px;
                line-height: 40px;
                padding: 0;
                height: 40px;
                color: #fff;
            }

            .navbar-collapse {
                padding: 0;
            }

            .navbar-nav > li > a {
                font-size: 11px;
                line-height: 40px;
                padding: 0 10px;
                height: 40px;
                color: #fff;
                opacity: .8;

                &:hover {
                    opacity: 1;
                }
            }

            .navbar-nav > li.disabled > a {
                opacity: .5;
                &:hover {
                    opacity: .5;
                }
            }

            .navbar-nav > .open > a,
            .navbar-nav > .active > a {
                opacity: 1;
            }

        }

        .btn-group {
            border-bottom: 1px solid #CCCCCC;
        }
    }

    .sidebar-search {
        border-bottom: 1px solid #CCCCCC;

        .form-control,
        .input-group-addon {
            font-size: 13px;
            border: none;
            height: 40px;
            background-color: transparent;
        }

        .form-control:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        .input-group-addon {
            padding-left: 0;
            padding-right: 0;
        }
        
        .input-group-btn > .btn {
            background-color: transparent;
            background-image: none;
            border: none;
            box-shadow: none;
            color: @gray;
        }

    }

    .sidebar-footer {
        width: @sidebar-width;
    }


    .sidebar-content {

        .nav-pills > li > a {
            color: @gray-dark;
            border-radius: 0;
        }

        .nav-pills > li.active > a {
            color: #fff;
        }

    }
}

.sidebar-header,
.content-header {

    > .navbar.navbar-default,
    > .navbar.navbar-success,
    > .navbar.navbar-warning,
    > .navbar.navbar-primary,
    > .navbar.navbar-info{
        background-image: none;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    > .navbar.navbar-default {
        background-color: #808080;
    }

    > .navbar.navbar-success {
        background-color: @brand-success;
        color: #fff;
        font-weight: bold;
    }

    > .navbar.navbar-warning {
        background-color: @brand-warning;
        color: #fff;
        font-weight: bold;
    }

    > .navbar.navbar-primary {
        background-color: @brand-primary;
        color: #fff;
        font-weight: bold;
    }

    > .navbar.navbar-info {
        background-color: @brand-info;
        color: #fff;
        font-weight: bold;
    }
}

.content {
    position: absolute;
    font-size: 13px;
    padding-top: 55px;
    padding-bottom: 55px;
    
    &.list{
        padding-top: 95px;
    }
    
    &.dns-log {
        width: 100%;
        padding-top: 95px;
    }
    
    .btn,
    .btn-default,
    .btn-primary,
    .btn-success,
    .btn-info,
    .btn-warning,
    .btn-danger {
        background-image: none;
        border: none;
        box-shadow: none;
    }

    .panel {
        border-radius: 0;
    }

    .panel-default > .panel-heading,
    .panel-primary > .panel-heading,
    .panel-info > .panel-heading,
    .panel-success > .panel-heading,
    .panel-warning > .panel-heading,
    .panel-danger > .panel-heading, {
        background-image: none;
        border-radius: 0;
    }

    .panel-default > .panel-heading {
        background-color: #CCCCCC;
        color: #4A4A4A;
    }

    .panel > .panel-body {
        h5 {
            margin-top: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid @gray-lighter;
            font-weight: bold;
        }
    }

    .radio,
    .checkbox {
        label {
            font-size: 14px;
        }

        p {
            padding-top: 10px;
            padding-left: 20px;
        }
    }

    .alert,
    .alert-success,
    .alert-info,
    .alert-warning,
    .alert-danger {
        background-image: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

}

.content.with-fixed-sidebar {
    padding-left: 275px;

    .content-header,
    .content-footer {
        left: 0;
        right: 0;
    }

    .content-header .navbar > .container,
    .content-footer > .container {
        padding-left: 275px;
    }


    .navbar-header {
        padding: 0 15px;
    }

    .navbar-form {
        padding: 0 15px;
        margin: 0;

        .form-control,
        .input-group-addon {
            font-size: 13px;
            border: none;
            height: 40px;
            background-color: transparent;
        }

        .form-control {
            width: 300px;
            &:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }

        .input-group-addon {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .dimmer {
        left: @sidebar-width;

    }

}

.content .content-header {
    position: fixed;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1010;

    > .navbar {
        .navbar-collapse {
            padding: 0;
        }

        .navbar-header > .navbar-brand,
        .navbar-nav > li > a,
        .navbar-nav > li > span{
            line-height: 40px;
            padding: 0 15px;
            height: 40px;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
        }

        .navbar-header > .navbar-brand {
            font-size: 14px;
        }

        .navbar-nav > li > a {
            font-size: 13px;
            opacity: .8;

            &:hover {
                opacity: 1;
            }
        }
        
        .navbar-nav > li > span {
            font-size: 13px;
            opacity: .8;
        }

        .navbar-nav > .open > a,
        .navbar-nav > .active > a,
        .navbar-nav > .open > span,
        .navbar-nav > .active > span {
            opacity: 1;
        }
        
        .navbar-nav > li.disabled > a,
        .navbar-nav > li.disabled > span{
            opacity: .5;
            &:hover {
                opacity: .5;
            }
            cursor: not-allowed;
        }

        &.navbar-default,
        &.navbar-success,
        &.navbar-primary,
        &.navbar-warning,
        &.navbar-info,
        &.navbar-search {
            margin-bottom: 0;
            min-height: 40px;

            .navbar-brand {
                color: #fff;
            }

            .navbar-nav > li > a,
            .navbar-nav > li > span {
                color: #fff;
            }

            .navbar-nav > .open > a,
            .navbar-nav > .active > a,
            .navbar-nav > .open > span,
            .navbar-nav > .active > span{
                background-color: @gray-dark;
                background-image: none;
            }

            .input-group-btn > .btn:not(.not-transparent) {
                background-color: transparent;
                background-image: none;
                border: none;
                box-shadow: none;
                color: @gray;
            }
        }

        &.navbar-search {
            background-color: #fff;

            .navbar-brand {
                color: @gray;
            }

            .navbar-nav > li > a,
            .navbar-nav > li > span{
                color: @gray;
            }

            .navbar-nav > .open > a,
            .navbar-nav > .active > a,
            .navbar-nav > .open > span,
            .navbar-nav > .active > span {
                background-color: @gray-lighter;
                background-image: none;
            }
        }
    }

    > .content-header {
        border-bottom: 1px solid #CCCCCC;

        .form-control,
        .input-group-addon {
            font-size: 13px;
            border: none;
            background-color: transparent;
        }
    }

    .btn-group {
        > .btn {
            font-weight: bold;
            background-image: none;
            border: none;
            font-size: 11px;
            line-height: 25px;
            padding: 0 10px;
        }

        > .btn-default {
            background-color: @gray-lighter;
        }

    }

}

.content .content-footer {
    position: fixed;
    -webkit-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1010;
    bottom: 0;
    background: #fff;

    .btn {
        background-image: none;
        border: none;
        box-shadow: none;
    }
    
    .navbar-btn {
        margin-top: 1px;
        margin-bottom: 1px;
    }
}

.content .content-dimmer {
    position: fixed;
    z-index: 1010;

    .dimmer-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 500px;
        margin-top: -100px;
        margin-left: -250px;
    }
    
    .btn-default {
        background-color: @gray-lighter;
    }

}

 /*
  * メッセージ表示用
  */
 .message {
   position: fixed;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 260px;
   text-align: center;
   vertical-align: middle;
 }
 .message > .content {
   position: absolute;
	
   top: 50%;
   left: 50%;
   width: 500px;
   margin-top: -100px;
   margin-left: -250px;
 }

/*
 * dnsチェックログ用
 */
.dns-content{
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

@media (max-width: @screen-sm-min) {

    .fixed-sidebar {
        position: relative;
    }

}


@media (max-width: @screen-sm-min) {

}

@media (min-width: @screen-md-min) {

}

@media (min-width: @screen-lg-min) {

}

/*
 * Domain group content
 */
.domain-group-list-tab {
    &.nav-tabs {
        background-color: transparent;
        border: none;

        > li > a,
        > .active > a {
            border: none;
            border-radius: 0;
        }

        > li {
            padding: 20px 10px;
            font-size: 11px;
            cursor: pointer;
        }

        > li > h1 {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        > .active {
            background-color: #fff;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }

        &.nav-justified {
            > li > a,
            > .active > a {
                border: none;
            }
        }
    }
}

.domain-group-list-state {
    &.table {
        font-size: 13px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 0;

        th, td {
            background-color: #fff;
        }

        th {
            width: 30%;
            text-align: center;
            color: #fff;
            font-weight: normal;
        }

        tr:first-child {
            th {
                border-top: none;
                background-color: @brand-primary;
            }
            td {
                border-top: none;
            }
        }

        tr > th {
            background-color: @brand-info;
        }
    }
}

.domain-group-list-table {
    background: #fff; 
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    
    .table > thead > tr > th, 
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        vertical-align: middle;
    }
    
    .form-control[disabled], 
    .form-control[readonly], 
    fieldset[disabled] .form-control {
         display: none;
    }

}

/*
 * 検索機能付きセレクトボックス
 */
.has-error {
    .search-selectbox {
        border-color: @state-danger-text;
        
        .form-control {
            box-shadow: none;
        }
    }
}

.search-selectbox {
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    color: #808080;
    
    &.disabled * {
        cursor: not-allowed;
        background-color: #ececec;
        opacity: 1;
        border-radius: 8px;
    }
    
    select,
    textarea {
        border: none;
        margin-bottom: 0 !important;
        box-shadow: none; 
        overflow:auto;
        
        &:focus {
            box-shadow: none;
        }

        &:read-only {
            background-color: #fff;
            cursor: auto;
            border: none;
        }
    }

    .input-group {
        padding: 2px 5px;
        
        input, button:not(.btn-circular), .input-group-addon {
            border: none;
            box-shadow: none;
            background: none;
            background-color: transparent;
        }
        
        .btn-circular {
            border-radius: 10rem;
            box-shadow: none;
            background-image: none;
        }
    }
    
    .input-group-lg .form-control {
        .input-lg();
    }
    .input-group-sm .form-control {
        .input-sm();
    }
    
    .input-group-xs .form-control {
        line-height: 1.5;
        height: 22px;
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: @input-border-radius-small;
    }
    
    .dropdown {
        .dropdown-toggle.btn-default {
            color: #808080;
            background-color: transparent;
            border-color: transparent;
        }
        
        .btn-default.btn-circular {
            color: #808080;
        }
        
        button:not(.btn-circular) {
            width: 100%;
            text-align: right;
            background: none;
            border:none;
            box-shadow:none;
            color: #808080;
            
            span {
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                max-width: 95%;
            }
        }
        
        .dropdown-menu {
            max-width: 55vw;
            overflow-y: auto;
            max-height: 33vh;
            min-width: 100%;
            
            .dropdown-header .input-group{
                border: 1px solid #ccc;
                border-radius: 8px;
            }
            
            a:hover {
                background-image:none;
            }
        }
    }
    
    .multiple {
        .input-group {
            border-bottom: 1px solid #ccc;
        }
    }
}

/*
 * Margin & Padding
 */
.margin-none {
    margin: 0 !important;
}

.margin-xs {
    margin: 5px !important;
}

.margin-sm {
    margin: 10px !important;
}

.margin-md {
    margin: 15px !important;
}

.margin-lg {
    margin: 20px !important;
}

.margin-xl {
    margin: 40px !important;
}

.margin-top-none {
    margin-top: 0 !important;
}

.margin-top-xs {
    margin-top: 5px !important;
}

.margin-top-sm {
    margin-top: 10px !important;
}

.margin-top-md {
    margin-top: 15px !important;
}

.margin-top-lg {
    margin-top: 20px !important;
}

.margin-top-xl {
    margin-top: 40px !important;
}

.margin-bottom-none {
    margin-bottom: 0 !important;
}

.margin-bottom-xs {
    margin-bottom: 5px !important;
}

.margin-bottom-sm {
    margin-bottom: 10px !important;
}

.margin-bottom-md {
    margin-bottom: 15px !important;
}

.margin-bottom-lg {
    margin-bottom: 20px !important;
}

.margin-bottom-xl {
    margin-bottom: 40px !important;
}

.margin-left-none {
    margin-left: 0 !important;
}

.margin-left-xs {
    margin-left: 5px !important;
}

.margin-left-sm {
    margin-left: 10px !important;
}

.margin-left-md {
    margin-left: 15px !important;
}

.margin-left-lg {
    margin-left: 20px !important;
}

.margin-left-xl {
    margin-left: 40px !important;
}

.margin-right-none {
    margin-right: 0 !important;
}

.margin-right-xs {
    margin-right: 5px !important;
}

.margin-right-sm {
    margin-right: 10px !important;
}

.margin-right-md {
    margin-right: 15px !important;
}

.margin-right-lg {
    margin-right: 20px !important;
}

.margin-right-xl {
    margin-right: 40px !important;
}

.padding-none {
    padding: 0 !important;
}

.padding-xs {
    padding: 5px !important;
}

.padding-sm {
    padding: 10px !important;
}

.padding-md {
    padding: 15px !important;
}

.padding-lg {
    padding: 20px !important;
}

.padding-xl {
    padding: 40px !important;
}

.padding-top-none {
    padding-top: 0 !important;
}

.padding-top-xs {
    padding-top: 5px !important;
}

.padding-top-sm {
    padding-top: 10px !important;
}

.padding-top-md {
    padding-top: 15px !important;
}

.padding-top-lg {
    padding-top: 20px !important;
}

.padding-top-xl {
    padding-top: 40px !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-bottom-xs {
    padding-bottom: 5px !important;
}

.padding-bottom-sm {
    padding-bottom: 10px !important;
}

.padding-bottom-md {
    padding-bottom: 15px !important;
}

.padding-bottom-lg {
    padding-bottom: 20px !important;
}

.padding-bottom-xl {
    padding-bottom: 40px !important;
}

.padding-left-none {
    padding-left: 0 !important;
}

.padding-left-xs {
    padding-left: 5px !important;
}

.padding-left-sm {
    padding-left: 10px !important;
}

.padding-left-md {
    padding-left: 15px !important;
}

.padding-left-lg {
    padding-left: 20px !important;
}

.padding-left-xl {
    padding-left: 40px !important;
}

.padding-left-xxl {
    padding-left: 70px !important;
}

.padding-right-none {
    padding-right: 0 !important;
}

.padding-right-xs {
    padding-right: 5px !important;
}

.padding-right-sm {
    padding-right: 10px !important;
}

.padding-right-md {
    padding-right: 15px !important;
}

.padding-right-lg {
    padding-right: 20px !important;
}

.padding-right-xl {
    padding-right: 40px !important;
}

.padding-right-xxl {
    padding-right: 70px !important;
}

.navbar-brand {
    font-family: "Roboto Thin";
}

/*gridのgutter調整*/
.gutter-none {
	margin-left:0px;
	margin-right:0px;
 
	> div{
		padding-right:0px;
		padding-left:0px;
	}
}
.gutter-xs {
	margin-left:-5px;
	margin-right:-5px;
 
	> div{
		padding-right:5px;
		padding-left:5px;
	}
}
.gutter-sm {
	margin-left:-10px;
	margin-right:-10px;
 
	> div {
		padding-right:10px;
		padding-left:10px;
	}
}
.gutter-md {
	margin-left:-15px;
	margin-right:-15px;
 
	> div{
		padding-right:15px;
		padding-left:15px;
	}
}
.gutter-lg {
	margin-left:-20px;
	margin-right:-20px;
 
	> div{
		padding-right:20px;
		padding-left:20px;
	}
}

.gutter-xl {
    margin-left:-40px;
	margin-right:-40px;
 
	> div{
		padding-right:40px;
		padding-left:40px;
	}
}

textarea{
    resize:none;
}

@media (max-width: @grid-float-breakpoint) {
    
    h1 {
        font-size: 24px;
    }
    
    .domain-group-collapse {
        display: none;
    }
    
    .datepicker .dropdown-menu {
        margin-left: 0;
        margin-right: 0;
    }
    
}

@media (min-width: @grid-float-breakpoint) {
    
    .domain-group {
        display: none;
    }
    
}
