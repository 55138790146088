@import "../../app";

/*
 * 変数 font-awesome上書き
 */
@fa-font-path: "../../../../fonts";

/*
 * 変数 bootstrap上書き
 */
@border-radius-base: 2px;

@brand-primary: #1976D2;
@brand-success:  #8BC34A;
@brand-info:     #00BCD4;
@brand-warning:  #FFC107;
@brand-danger:   #f44336;

@btn-default-color: #fff;
@btn-default-bg: #9E9E9E; 
@btn-default-border: #9E9E9E;

@alert-danger-bg: @brand-danger;
@alert-danger-border: @brand-danger; 
@alert-danger-text: #fff;
@alert-success-bg: @brand-success;
@alert-success-border: @brand-success;
@alert-success-text: #fff;
@alert-info-bg: @brand-info;
@alert-info-border: @brand-info;
@alert-info-text: #fff;
@alert-warning-bg: @brand-warning;
@alert-warning-border: @brand-warning;
@alert-warning-text: #fff;

@pagination-border: transparent;
@pagination-disabled-border: transparent;

@navbar-inverse-color:                      #fff;
@navbar-inverse-bg:                         @base-color;
@navbar-inverse-link-color:                 #fff;
@navbar-inverse-link-hover-color:           rgba(255, 255, 255, 0.7);
@navbar-inverse-link-active-color:          #fff;
@navbar-inverse-brand-hover-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-toggle-border-color:        #fff;
@navbar-inverse-toggle-hover-bg:            darken(@base-color, 10%);

/*
 * 変数
 */
@base-color: #03A9F4;

@border-radius-btn: 20px;

@domain-group-list-tab-default: lighten(@panel-default-text, 30%);
@domain-group-list-tab-success: @brand-success;
@domain-group-list-tab-warning: @brand-warning;
@domain-group-list-tab-danger: @brand-danger;

/*
 * 全般
 */
* {
    background-image: none !important;
    box-sizing: border-box;
}

body {
    background: #fff;
}

/*
 * pagination
 */
.pagination {
    > li {
        > a,
        > span {
            margin-left: 2px;
            border-radius: @border-radius-base;
        }
        &:first-child {
            > a,
            > span {
                margin-left: 2px;
                .border-left-radius(@border-radius-base);
            }
        }
        &:last-child {
            > a,
            > span {
                .border-right-radius(@border-radius-base);
            }
        }
    }
}

/*
 * button
 */
.button-variant(@color; @background; @border) {
    color: @background;
    background-color: #fff;
    border-color: @border !important;
    
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        color: @color;
        background-color: @background;
        border-color: @border !important;
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: transparent;
            border-color: @border !important;
        }
    }
    
    .badge {
        color: @background;
        background-color: transparent;
        border-color: @border !important;
    }
}

.btn:not(.btn-link) {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    
    &:hover,
    &:focus,
    &.focus {
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    }
    
    &:active,
    &.active {
        .box-shadow(inset 0 4px 5px rgba(0,0,0,.125));
    }
    
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        .opacity(.55);
    }
}

.input-btn .btn,
.input-group-btn .btn {
    box-shadow: none;
}

.btn-default {
    .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

.btn-lg {
    .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-base);
}
.btn-sm {
    .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-base);
}
.btn-xs {
    .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @border-radius-base);
}

/*
 * button group
 */
.btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: 5px;
    }
}

.btn-group-justified {
    border-spacing: 3px 0;
}

/*
 * info group
 */
.info-group-brand-styles(@color) {
    background-color: #ececec;
    color: @color;
    border-color: @color;
    font-weight: bold;
}

.info-group-body-styles(@color) {
    color: @color;
    background-color:transparent;
}

.info-group {
    border-left: 5px solid;
    border-bottom: 1px solid;
    border-color: #888;
    
    > .info-group-body {
        > label {
            margin-bottom: 0;
        }
    }
    
    // default
    &.info-group-default {
        border-color: lighten(@gray, 15%);
        
        > .info-group-addon:first-child {
            .info-group-brand-styles(lighten(@gray, 15%));
        }
    }
    
    // primary
    &.info-group-primary {
        border-color: @brand-primary;
    }
    &.info-group-primary > .info-group-addon,
    > .info-group-addon.info-group-addon-primary {
        .info-group-brand-styles(@brand-primary);
    }
    &.info-group-primary > .info-group-body,
    > .info-group-body.info-group-body-primary {
        .info-group-body-styles(@brand-primary);
    }
    
    // success
    &.info-group-success {
        border-color: @brand-success;
    }
    &.info-group-success > .info-group-addon,
    > .info-group-addon.info-group-addon-success {
        .info-group-brand-styles(@brand-success);
    }
    &.info-group-success > .info-group-body,
    > .info-group-body.info-group-body-success  {
        .info-group-body-styles(@brand-success);
    }
    
    // info
    &.info-group-info {
        border-color: @brand-info;
    }
    &.info-group-info > .info-group-addon,
    > .info-group-addon.info-group-addon-info {
        .info-group-brand-styles(@brand-info);
    }
    &.info-group-info > .info-group-body,
    > .info-group-body.info-group-body-info  {
        .info-group-body-styles(@brand-info);
    }
    
    // warning
    &.info-group-warning {
        border-color: @brand-warning;
    }
    &.info-group-warning > .info-group-addon,
    > .info-group-addon.info-group-addon-warning {
        .info-group-brand-styles(@brand-warning);
    }
    &.info-group-warning > .info-group-body,
    > .info-group-body.info-group-body-warning  {
        .info-group-body-styles(@brand-warning);
    }
    
    // danger
    &.info-group-danger {
        border-color: @brand-danger;
    }
    &.info-group-danger > .info-group-addon,
    > .info-group-addon.info-group-addon-danger {
        .info-group-brand-styles(@brand-danger);
    }
    &.info-group-danger > .info-group-body,
    > .info-group-body.info-group-body-danger  {
        .info-group-body-styles(@brand-danger);
    }
}

/*
 * label
 */
.label-variant(@color) {
    border: 1px solid @color;
    color: @color !important;
    background-color: transparent;
}

.label {
    margin-left: 2px;
}

/*
 * navbar
 */
.navbar-fixed-bottom {
    background-color: #fff;
}

.fixed-header .navbar:first-child .navbar-brand {
    padding: 8px 15px;
}

.navbar-primary {
    background: #fff;
    color: rgba(0, 0, 0, 0.54);
    
    .navbar-toggle {
        border-color: rgba(0, 0, 0, 0.38);
        
        &:hover,
        &:focus {
            background-color: rgba(0, 0, 0, 0.15);
        }
        .icon-bar {
            background-color: rgba(0, 0, 0, 0.38);
        }
    }
    
    .nav > li > a {
        color: rgba(0, 0, 0, 0.54);
        
        &:focus,
        &:hover {
            color: rgba(0, 0, 0, 0.70);
            background: transparent;
        }
    }
}

/*
 * panel
 */
.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border) {
    border-color: @border;
    
    & > .panel-heading {
        color: @heading-bg-color;
        background-color: transparent;
        border-color: @heading-border;
        
        + .panel-collapse > .panel-body {
            border-top-color: @border;
        }
        .badge {
            color: @heading-bg-color;
            background-color: @heading-text-color;
        }
    }
    & > .panel-footer {
        + .panel-collapse > .panel-body {
            border-bottom-color: @border;
        }
    }
}

.panel-heading-styles(@text-color; @bg-color; @border-color) {
    color: @text-color;
    border-bottom-color: @border-color;
    background-color: @bg-color;
}

.panel-default {
    .panel-variant(lighten(@panel-default-text, 30%); @panel-default-text; lighten(@panel-default-text, 30%); lighten(@panel-default-text, 30%));
}

.panel-heading {
    border-top: 5px solid transparent;
}

.panel {
    position: relative;
    box-shadow: none;
    border-color: #ddd;
    
    .panel-body {
        .progress:last-child {
            position: absolute;
            left: 0;
            top: -4px;
            width: 100%;
            box-shadow: none;
            margin-bottom: 0;
        }
    }
    
    > .panel-heading {
        padding: 12px 15px;
        
        &:first-child > .btn:first-child {
            margin-right: 0;
        }
        
        > a.btn,
        > .btn-group > a.btn {
            padding-top: 6px;
        }
        
        > .btn {
            height: auto;
            margin: -6px 0;
        }
        
        .btn {
            &:first-child {
                margin-right: 0;
            }
            
            + .btn {
                margin-right: 5px;
            }
            
            .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
        }
    }
    
    > .list-group {
        box-shadow: none;
        
        li.list-group-item {
            > div div {
                margin-top: 5px;
            }
        }
    }
    
    > .panel-heading-default {
        .panel-heading-styles(@panel-default-text; lighten(@panel-default-heading-bg, 3%); lighten(@panel-default-border, 5%));
    }
    
    > .panel-heading-primary {
        .panel-heading-styles(darken(@panel-primary-text, 50%); lighten(@panel-primary-heading-bg, 35%); lighten(@panel-primary-border, 30%));
    }
    
    > .panel-heading-success {
        .panel-heading-styles(darken(@panel-success-text, 50%); lighten(@panel-success-heading-bg, 35%); lighten(@panel-success-border, 30%));
    }
    
    > .panel-heading-info {
        .panel-heading-styles(darken(@panel-info-text, 50%); lighten(@panel-info-heading-bg, 50%); lighten(@panel-info-border, 45%));
    }
    
    > .panel-heading-warning {
        .panel-heading-styles(darken(@panel-warning-text, 50%); lighten(@panel-warning-heading-bg, 35%); lighten(@panel-warning-border, 30%));
    }
    
    > .panel-heading-danger {
        .panel-heading-styles(darken(@panel-danger-text, 50%); lighten(@panel-danger-heading-bg, 35%); lighten(@panel-danger-border, 30%));
    }
}

/*
 * datepicker
 */
.datepicker table .btn-default:hover {
    color: @btn-default-bg;
}

/*
 * well
 */
.well {
    position: relative;
    padding: .25em 0 .5em .75em;
    border: none;
    border-left: 6px solid @base-color;
    border-radius: 0;
    background: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.54);
}

.well::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 0;
    border-bottom: 1px solid #ccc;
}

/*
 * ドメイン管理
 */
.fixed-sidebar {
    &.sidebar-left .btn-group > .btn {
        border: 1px solid;
    }
}

.content {
    .panel {
        > .panel-body {
            h5 {
                position: relative;
                padding: .25em 0 .5em .75em;
                border: none;
                border-left: 6px solid @base-color;
                border-radius: 0;
                background: none;
                box-shadow: none;
                color: rgba(0, 0, 0, 0.54);
                margin-bottom: 15px;
            }
            
            h5::after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                width: 100%;
                height: 0;
                border-bottom: 1px solid #ccc;
            }
        }
    }
    
    .panel-default > .panel-heading   {
        background-color: transparent;
    }
    
    .content-header {
        .btn-group > .btn {
            border: 1px solid;
        }
    }
    
    .btn {
        border: 1px solid;
    }
    
    .content-footer {
        .btn {
            border: 1px solid;
        }
        
        .pull-left {
            padding-bottom: 5px;
        }
    }
    
    .content-dimmer, .dialog {
        .btn-default {
            .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
        }
    }
}

.content .content-header, 
.fixed-sidebar.sidebar-left {
    .btn-group > .btn-default {
        .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
    }
}

.dialog {
    bottom: 42px;
    
    [class^="label-"]{
        border-width: 5px 0px 1px 0px;
    }
}

.domain-group-list-tab {
    > li.active {
        border-top: 5px solid @domain-group-list-tab-default;
        
        &.domain-group-list-tab-success {
            border-color: @domain-group-list-tab-success;
        }
        
        &.domain-group-list-tab-warning {
            border-color: @domain-group-list-tab-warning;
        }
        
        &.domain-group-list-tab-danger {
            border-color: @domain-group-list-tab-danger;
        }
    }
}

/*
 * 検索機能付きセレクトボックス
 */
.search-selectbox {
    .btn-default:not(.btn-circular):hover {
        color: #808080;
    }
}

